import {useField} from 'remix-validated-form';

import {useTranslations} from '@/hooks/useTranslations';
import {
  default as SharedSelect,
  type SelectProps,
} from '@/components/shared/Select/Select';

export function Select({id, ...inputProps}: SelectProps) {
  const {t} = useTranslations();
  const {error, getInputProps} = useField(id);

  let errorMessage;
  if (error) {
    errorMessage = t(error);
  }

  return (
    <SharedSelect
      {...getInputProps({id: id, ...inputProps})}
      error={errorMessage}
    />
  );
}
